import { Dialog, Slide, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useState } from "react";
import Spinner from "../../../components/spinner";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import MuiAlert from "@mui/material/Alert";
import { addCoupon } from "../../../apis/coupon";
import toast from "react-hot-toast";
import { addPricing } from "../../../apis/pricing";
import { useParams } from "react-router-dom";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IS_REQUIRED = true;
function AddPricing({ open, handleClose }) {
  const queryClient = useQueryClient();
  const { id } = useParams(); // Extract the id from the URL
console.log(id);

  const initialstate = {
    id: id, // Default value if required
    price_per_unit: "",
    each_unit_value: "",
    stepper: "",
    mrp_price: "",
    selling_price: "",
      from_date: '',
        to_date: ''
  };
  const [couponFormData, setCouponFormData] = useState(initialstate);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const closeErrorMessage = () => {
    setIsError(false);
  };
  const handleCouponFormData = (e) => {
    let $this = e.target;
    console.log($this.type);
    let value = $this.value;
    let data = {
      ...couponFormData,
      [$this.name]: $this.type === "date" ? value : value,
    };
    setCouponFormData(data);
  };

  const handleCloseAndReset = () => {
    setIsSuccess(false);
    setIsError(false);
    setCouponFormData(initialstate);
    handleClose();
  };
  const setCouponDataToServer = useMutation((data) => addPricing(data), {
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.invalidateQueries("pricinglist");
        setIsSuccess(true);
        setTimeout(() => {
          handleCloseAndReset();
        }, 2000);
        toast.success("Pricing Added Successfully", {
          position: "top-right",
          duration: 3000,
        });
      }
    },
    onError: () => {
      setIsError(true);
      toast.error("Oops! Something Went Wrong", {
        position: "top-right",
        duration: 3000,
      });
    },
  });
  const handleSubmitCouponForm = (e) => {
    e.preventDefault();
    setCouponDataToServer.mutate(couponFormData);
  };
  return (
    <Dialog
      fullScreen
      fullWidth={true}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div className="container_xxl px-4">
        <div className="flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white">
          <div>
            <IconButton onClick={handleClose}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div>
            <h1 className="heading">Add Pricing</h1>
          </div>
        </div>
        <div className="form-body py-8">
          {isError && (
            <Alert
              onClose={closeErrorMessage}
              className="mb-4"
              severity="error"
              sx={{ width: "100%" }}
            >
              {setCouponDataToServer?.error?.message}, Please try again later
            </Alert>
          )}
          {isSuccess && (
            <Alert
              onClose={closeErrorMessage}
              className="mb-4"
              severity="success"
              sx={{ width: "100%" }}
            >
              Category Added Succesfully
            </Alert>
          )}
          <form
            className=""
            autoComplete="off"
            onSubmit={handleSubmitCouponForm}
          >
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="price_per_unit" className="label">
                  Price Per Unit (kg) <small className="text-red-700">*</small>
                </label>
                <input
                  type="text"
                  required={IS_REQUIRED}
                  placeholder="Enter Price Per Unit"
                  value={couponFormData.price_per_unit}
                  onChange={handleCouponFormData}
                  name="price_per_unit"
                  className="input"
                />
              </div>
              <div>
              <label htmlFor="each_unit_value" className="label">
                                    Each Unit Value <small className="text-red-700">*</small>
                                </label>
                                <input
                                    type="number"
                                    required={IS_REQUIRED}
                                    placeholder="Enter Each Unit Value"
                                    value={couponFormData.each_unit_value}
                                    onChange={handleCouponFormData}
                                    name="each_unit_value"
                                    className="input"
                                />
              </div>
              <div>
              <label htmlFor="stepper" className="label">
                                    Stepper <small className="text-red-700">*</small>
                                </label>
                                <input
                                    type="number"
                                    required={IS_REQUIRED}
                                    placeholder="Enter Stepper"
                                    value={couponFormData.stepper}
                                    onChange={handleCouponFormData}
                                    name="stepper"
                                    className="input"
                                />
              </div>
              <div>
              <label htmlFor="mrp_price" className="label">
                                    MRP Price <small className="text-red-700">*</small>
                                </label>
                                <input
                                    type="number"
                                    required={IS_REQUIRED}
                                    placeholder="Enter MRP Price"
                                    value={couponFormData.mrp_price}
                                    onChange={handleCouponFormData}
                                    name="mrp_price"
                                    className="input"
                                />
              </div>
              <div>
              <label htmlFor="selling_price" className="label">
                                    Selling Price <small className="text-red-700">*</small>
                                </label>
                                <input
                                    type="number"
                                    required={IS_REQUIRED}
                                    placeholder="Enter Selling Price"
                                    value={couponFormData.selling_price}
                                    onChange={handleCouponFormData}
                                    name="selling_price"
                                    className="input"
                                />
              </div>
              <div className='input-container'>
                                <label htmlFor="Coupon Valid From" className='label'> From Date<small className="text-red-700">*</small></label>
                                <input required={IS_REQUIRED} type="date" placeholder='Enter From Date' name='from_date' value={couponFormData.from_date} onChange={handleCouponFormData} className='input' />
                            </div>
                            <div className='input-container'>
                                <label htmlFor="Coupon Valid Till" className='label'>To Date </label>
                                <input type="date" placeholder='Enter To Date' name='to_date' value={couponFormData.to_date} onChange={handleCouponFormData} className='input' />
                            </div>
             
            </div>
            <div className="text-center my-6">
              {setCouponDataToServer.isLoading ? (
                <Spinner />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className="primaryBtn-contained"
                >
                  Save
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
}

export default AddPricing;
