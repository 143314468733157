import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config"; 
import Cookies from "universal-cookie";

// const cookies = new Cookies();
let token = sessionStorage.getItem(TOKEN_KEY);
export const addPricing = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    let letsToken = sessionStorage.getItem(TOKEN_KEY);
    let fd = new FormData()
    for (var key in data) {
        if(Array.isArray(data[key])){
            fd.append(key, JSON.stringify(data[key]));
        }else{
            fd.append(key, data[key]);
        }
    }
    let res = await axios({ method: "POST", data:fd, url: `${process.env.REACT_APP_API_ENDPOINT}product_price_details_create`,headers: { Authorization: letsToken ? `Bearer ${letsToken}` : '', }})
    return res
};
export const deletePricing= async (price_id) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    fd.append('price_id',price_id)
    let res = await axios({ method: "POST", data:fd, url: `${process.env.REACT_APP_API_ENDPOINT}product_price_details_delete`,headers: { Authorization: token ? `Bearer ${token}` : '', }})
    return res
};