import logo from "./assets/images/common/logo.png";
export const PROJECT_NAME = "Naysha Farms";
export const PROJECT_META_NAME = "naysha_farms";
export const ENVIRONMENT = "production"; //production | development
export const TOKEN_KEY =
  ENVIRONMENT === "developement"
    ? `_${PROJECT_META_NAME}_token`
    : `_${PROJECT_META_NAME}_token_dev`;
export const SITE_KEY =
  ENVIRONMENT === "developement"
    ? "6Ld0BLkmAAAAAECagQW1dA8yMo5gOUDq51lB7aS5"
    : "6Ld0BLkmAAAAAECagQW1dA8yMo5gOUDq51lB7aS5";

// export const API_ENDPOINT =
//   ENVIRONMENT === "production"
//     ? `http://stage-api.nayshafarms.com/`
//     : "http://stage-api.nayshafarms.com/";
{
  
}
export const IDLE_TIME_IN_SECONDS = 300;

export const ENCRYPT_AND_DECRYPT_KEY = "wjEXA38AgXM1FWTxojcsBqykcV9NpX3h";
export const DATA_VECTOR = "dGEBTb1YQszEhk5G";

export const LOGO = logo;
